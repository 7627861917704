<template>
  <div class="events" v-if="data && chromeData">
    <Hero title="Évènements" :picture="chromeData?.headers.events"> </Hero>
    <transition name="fade" mode="out-in">
      <div>
        <div
          class="events-inner"
          v-for="(month, index) in data.events"
          :key="`month-${index}`"
        >
          <h3>{{ index }}</h3>

          <Card
            pageType="event"
            :data="event"
            v-for="(event, index) in month"
            :key="`events-${index}`"
          />
        </div>
      </div>
    </transition>
  </div>
  <div v-else>
    <Error />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { chrome } from '@/guides/endpoints/chrome'
import { list } from '@/guides/endpoints/events'

import Card from '@/components/Card.vue'
import Error from '@/components/Error.vue'
import Hero from '@/components/Hero.vue'

export default defineComponent({
  name: 'Events',
  components: {
    Card,
    Error,
    Hero,
  },
  setup() {
    const chromeData = ref()
    const data = ref()

    chrome().then(r => {
      chromeData.value = r.data
      console.log('chrome', r.data)
    })

    list().then(r => {
      data.value = r.data.content
    })

    return {
      chromeData,
      data,
    }
  },
})
</script>

<style lang="scss" scoped>
.events {
  ::v-deep {
    .content {
      @include fluid(
        margin-top,
        (
          xxs: -105px,
          m: -195px,
        )
      );
    }

    .card__picture-outer {
      @include mq(m) {
        width: 12rem;
      }
    }
  }
}

.events-inner {
  margin: 0 $spacing * 1.35;

  h3 {
    text-transform: capitalize;
  }

  @include mq($until: m) {
    h3 {
      margin-top: 4rem;
    }
  }

  @include mq(m) {
    width: calc(100% - 8rem);
    max-width: 600px;
    margin: 4rem auto;
  }
}

.card {
  margin-top: 1.5rem;

  @include mq(m) {
    margin-top: $spacing * 1.35;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
